(function($) {
    // var reInitGCaptcha;
    //
    // reInitGCaptcha = function() {
    //     $.getScript("https://www.google.com/recaptcha/api.js?render=6LfJs7gkAAAAAPu6lvNhDAzHkhrLInNMxw-tfbAN").done(function () {
    //         grecaptcha.ready(function () {
    //             grecaptcha.execute('6LfJs7gkAAAAAPu6lvNhDAzHkhrLInNMxw-tfbAN', {action: 'form'})
    //                 .then(function (token) {
    //                     $('#g-token').val(token);
    //                 });
    //         });
    //     });
    // };
    //
    // if ($('form[data-recaptcha]').length) {
    //     reInitGCaptcha();
    //     setInterval(function(){reInitGCaptcha();}, 150000);
    // }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    const masonry = $("[data-masonry-col]");

    if (masonry.length) {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
            $.getScript(cdnjs.masonry).done(function () {
                cssLoaded(function () {
                    $("[data-masonry-col]").each(function(){
                        let elm = $(this);
                        elm.masonry({
                            itemSelector: '.col--masonry',
                            columnWidth: elm.data("masonry-col"),
                            percentPosition: true
                        });
                    });
                })
            });
        }
    }

    nl_lib_dialog.init(function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    // $(".part_ui_btn").nl_lib_ripple();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload,function () {
                    let frm = doc.find("#"+Object.keys(payload.snippets)[0]);
                    let status = frm.find(".part_ui_alert");
                    if(status.length) {
                        setTimeout(function () {
                            status.find("[data-click-dismiss]").trigger("click");
                        },5000);
                    }
                });
            }
        });
    });

    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if ($("[data-tilt]").length) {
        $.getScript(cdnjs.tilt).done(()=> {
            $("[data-tilt]").tilt({
                maxTilt: 15,
                perspective: 1200,   // Transform perspective, the lower the more extreme the tilt gets.
                glare: true,  // Enables glare effect
                maxGlare: 0.1       // From 0 - 1.
            });
        });
    }

    doc.on("click","[data-scroll-top]",function () {
        doc.find("section").first().anchor_anim(500);
    });

    if ("onhashchange" in window) {
        win.on("hashchange",function () {
            seoMan();
        })
    }
    function seoMan(){
        if(window.location.hash === "#seoman"){
            if(!doc.find("#seoman").length){
                console.log("SEO testuji zásadně na vývojové doméně");
                $("#layout").prepend("<div id=\"seoman\"><img src=\"/img/seoman.png\" /></div>")
            }
        }
    }
    seoMan();
})(jQuery);

//____ TABS SWITCH CONTENT ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
$.fn.nl_lib_switch = function (callback) {
    $(this).each(function () {
        let elm = $(this);

        let nav = '[data-switch-nav="'+elm.find("[data-switch-nav]").first().data("switch-nav")+'"]',
            area = '[data-switch-area="'+elm.find("[data-switch-area]").first().data("switch-area")+'"]',
            nav_item = "[data-nav-item]",
            area_item = "[data-area-item]",
            select = '[data-switch-select="'+elm.find("[data-switch-nav]").first().data("switch-nav")+'"]';

        $.fn.nl_lib_switch_click = function (n, parent) {
            $(this).on("click", function (e) {
                e.preventDefault();
                $(this).addClass("mod--active").closest(nav).find(nav_item).not(this).removeClass("mod--active");

                if (parent === nav) {
                    $(this).closest(elm).find(area).children(nav_item).removeClass("mod--active").eq(n).addClass("mod--active");
                } else {
                    $(this).closest(elm).find(nav).find(nav_item).removeClass("mod--active").eq(n).addClass("mod--active");
                }

                $(this).closest(elm).find(area).each(function() {
                    $(this).children(area_item).hide().removeClass("mod--active").eq(n).show().addClass("mod--active");

                    if (callback) {
                        callback(n);
                    }
                });
                
                $(select)[0].selectedIndex = n;
            });
        };

        elm.find(select).first().on("change",function () {
            elm.find(nav).first().find(nav_item).eq($(this)[0].selectedIndex).trigger("click");
        });

        elm.find(nav).first().find(nav_item).each(function(n) {
            $(this).nl_lib_switch_click(n,nav);
        });
        elm.find(area).first().children(nav_item).each(function(n) {
            $(this).nl_lib_switch_click(n,area);
        });
    });
};
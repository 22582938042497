(function ($) {
    let $fn = $(".comp_gallery");

    if ($fn.length) {
        let $gallery = $fn.find("[data-gallery]");

        if($gallery.length) {
            $.getScript(cdnjs.lightgallery).done(function () {
                $gallery.lightGallery({
                    thumbnail: true,
                    selector: ".elm_gallery_item",
                    exThumbImage: 'data-exthumbimage',
                    fullScreen: false,
                    zoom: true,
                    actualSize: false,
                    hash: false,
                    download: true,
                    autoplay: false,
                    autoplayControls: false
                });
            });
        }
    }
})(jQuery);
(function ($) {
    let $fn = $(".comp_product_detail_specs");

    if ($fn.length) {
        let isInViewport = setInterval(function(){
            if(win.scrollTop()+win.height() >= $fn.find(".elm_item").first().offset().top){
                clearInterval(isInViewport);
                $fn.find('.elm_value').each(function () {
                    if($(this).text().indexOf(".") > -1){
                        $(this).prop('Counter', 0).animate({
                            Counter: $(this).text()
                        }, {
                            duration: 3000,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(+(Math.round(now + "e+" + 2)  + "e-" + 2));
                            }
                        });
                    }
                    else {
                        $(this).prop('Counter', 0).animate({
                            Counter: $(this).text()
                        }, {
                            duration: 2000,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(Math.ceil(now));
                            }
                        });
                    }
                });
            }
        },0);
    }
})(jQuery);